<template>
  <div style="width:100%;height:100%;overflow:hidden;">
    <v-btn fab style="position:fixed;right:10px;top:72px;" :href="'https://drive.google.com/uc?export=download&id=' + fileLink" aria-label="Download File">
      <v-icon>fal fa-download</v-icon>
    </v-btn>
    <iframe :src="source" style="width:100%;height:100%;border:0"></iframe>
  </div>
</template>
<script>
import { ref, computed, watch, onBeforeUnmount } from '@vue/composition-api'

export default {
  setup (props, { root }) {
    const id = computed(() => root.$route.params.entry)
    const source = ref('')
    const fileLink = ref('')

    watch(id, () => {
      if (id.value !== '' && /[a-f\d]{24}/.test(id.value)) {
        root.$feathers.service('library/archive/entry').get(id.value).then((data) => {
          document.title = data.title
          root.$store.commit('main/setPageSubTitle', data.title)
          fileLink.value = data.fileLink
          if ('resourceKey' in data && data.resourceKey != null && data.resourceKey !== '') {
            fileLink.value += '&resourcekey=' + data.resourceKey
            source.value = 'https://drive.google.com/file/d/' + data.fileLink + '/preview?resourcekey=' + data.resourceKey
          } else {
            source.value = 'https://drive.google.com/file/d/' + data.fileLink + '/preview'
          }
        })
      }
    })

    onBeforeUnmount(() => {
      root.$store.commit('main/setPageSubTitle', '')
    })

    return {
      id,
      source,
      fileLink
    }
  }
}
</script>
